import React from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton
} from 'react-accessible-accordion';
import Layout from "../components/App/Layout";
import Navbar from "../components/App/Navbar";
import Footer from "../components/App/Footer";
import Header from '../components/Index/Header';
import { graphql, useStaticQuery } from "gatsby";
import ReactMarkdown from 'react-markdown';
import raw from 'rehype-raw';
import gfm from 'remark-gfm';

const query = graphql`
{
    strapiFaq {
        features {
          question_ans
          question_title
        }
        details
        header
        load_desc
        load_header
    }
}
`



export default function FAQ() {
    const data = useStaticQuery(query);
    return (
        <Layout>
            <Navbar />
            <Header />
            <div className="ptb-100">
                <div className="container">
                    <div className="faq-accordion">
                        <Accordion>
                            {data.strapiFaq.features.map((feature, index) => {
                                return (
                                    <AccordionItem key={index}>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                {feature.question_title}
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <ReactMarkdown children={feature.question_ans} remarkPlugins={[gfm]} rehypePlugins={[raw]} />
                                        </AccordionItemPanel>
                                    </AccordionItem>);
                            })}
                        </Accordion>
                    </div>
                </div>
            </div>
            <Footer />
        </Layout>
    );
};